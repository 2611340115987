import axios from "@/axios"

const url = "/api/brain/others/tope/reasons/"

class TopesReasonsService {
	async getTopesReasons({ search }) {
		try {
			const data = await axios.get(`${url}`, {
				params: {
					search,
				},
			})
			return data
		} catch (error) {
			throw error
		}
	}

	async storeReason({ name }) {
		try {
			const { data } = await axios.post(`${url}`, {
				name,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async editReason({ id, name }) {
		try {
			const data = await axios.put(`${url}${id}`, { name })
			return data
		} catch (error) {
			throw error
		}
	}

	async deleteReason({ id }) {
		try {
			const data = await axios.delete(`${url}${id}`)
			return data
		} catch (error) {
			throw error
		}
	}
}

export default new TopesReasonsService()
