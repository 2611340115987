<template>
	<div>
		<custom-light-box :items="videos" :index="index" :effect="'fade'" @close="index = null" />

		<b-tabs v-model="indexTab" nav-wrapper-class="card pt-1 px-2 lg-px-1 mb-2" v-if="origin === 'main'">
			<b-tab :disabled="false">
				<template #title>
					Topes
					<b-badge :variant="indexTab === 0 ? 'primary' : 'light-primary'" style="margin-left: 0.5rem">
						{{ getCounterTope }}
					</b-badge>
				</template>
			</b-tab>
			<b-tab :disabled="false">
				<template #title>
					Torneos
					<b-badge :variant="indexTab === 1 ? 'primary' : 'light-primary'" style="margin-left: 0.5rem">
						{{ getCounterTournament }}
					</b-badge>
				</template>
			</b-tab>
		</b-tabs>

		<b-card no-body class="mb-1">
			<div class="m-2">
				<div v-if="!id_specimen" class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span class="text-muted">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						v-if="!id_specimen"
						cols="12"
						md="6"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
					</b-col>
					<b-col v-else cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label class="mr-2">Refrescar</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
					</b-col>
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Placa / alias / cintillo en la ala"
								@keypress.enter="resetSearch"
							/>
							<template v-if="!id_specimen">
								<b-button
									variant="primary"
									v-if="isAdmin || isPreparador || isSupervisor"
									@click="modalAddVideoBySpecimen = true"
								>
									<!--  -->
									<span class="text-nowrap">Agregar video</span>
								</b-button>
								<button
									v-if="!id_specimen"
									@click="sidebarFilters = !sidebarFilters"
									class="ml-1 btn btn-outline-primary btn-buscar-placa"
								>
									<feather-icon icon="FilterIcon" />
								</button>
							</template>
						</div>
					</b-col>
				</b-row>
			</div>

			<!-- {{  items}} -->

			<div class="table-responsive">
				<b-table-simple class="position-relative">
					<b-thead>
						<b-tr class="text-center">
							<b-th class="p-1" v-if="id_specimen">PUNTAJE</b-th>
							<b-th class="p-1">VIDEO</b-th>
							<b-th class="p-1" v-if="!id_specimen">EJEMPLAR</b-th>
							<b-th class="p-1">RIVAL</b-th>
							<b-th class="p-1" v-if="indexTab === 1">RESULTADO</b-th>
							<b-th class="p-1" v-if="id_specimen">PUNTAJE RIVAL</b-th>
							<b-th class="p-1">FECHA</b-th>
							<b-th class="p-1">SUBIDO POR</b-th>
							<b-th class="p-1" v-if="isAdmin || isSupervisor || isPreparador">ACCIONES</b-th>
						</b-tr>
					</b-thead>

					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in items" :key="index">
							<b-td v-if="id_specimen">
								<template v-for="(score, indexSc) in item.rival_specimens_score">
									<div :key="indexSc" v-if="score.id == id_specimen" class="d-flex flex-column">
										<div class="d-flex align-items-center justify-content-center">
											<span>{{ Number(score.score).toFixed(2) }}</span>
											<feather-icon
												icon="StarIcon"
												size="17"
												class="text-warning"
												style="margin-left: 5px"
											/>
										</div>
										<span>{{ score.user_name }}</span>
									</div>
								</template>
							</b-td>

							<b-td class="text-center">
								<div
									v-if="item.video.status == '2' && item.video.url"
									class="d-flex align-items-center justify-content-center"
								>
									<span @click="viewSpecimen(item, index)" style="cursor: pointer">
										<b-avatar
											:src="item.video.thumb"
											rounded
											size="80"
											icon="film"
											badge
											badge-variant="danger"
										>
											<template #badge>{{ item.video.duration }}</template>
										</b-avatar>
									</span>
									<div class="d-flex flex-column justify-content-center align-items-center ml-1">
										<feather-icon
											@click="selectBattle(item)"
											class="cursor-pointer"
											icon="Edit3Icon"
											size="18"
										/>
										<feather-icon
											class="cursor-pointer text-danger mt-1"
											icon="DownloadIcon"
											size="20"
											v-b-tooltip.hover="`Descargar`"
											@click="downloadVideo(item)"
											v-if="isAdmin && item.video.status == '2' && item.video.url"
										/>
									</div>
								</div>

								<div v-else-if="!item.video.url">
									<div v-if="item.category != 'torneo'">
										<b-button
											class="btn-icon"
											style="padding: 2px; margin: 0 0 4px 4px"
											variant="outline-primary"
											size="sm"
											title="Subir video de tope"
											v-b-tooltip.hover
											@click="selectBattle(item)"
										>
											<feather-icon class="cursor-pointer" size="16" icon="PlusIcon" />
										</b-button>
									</div>
									<UploadFightVideo v-else @refresh="refresh" :info="item" />
								</div>
								<div v-else>
									<p class="mb-0">Procesando video</p>
								</div>
							</b-td>

							<b-td v-if="!id_specimen" class="text-center">
								<template v-if="item.local">
									<template v-if="item.local && item.local.type == 'specimen'">
										<SpecimenPlate
											:specimen="{
												plate: item.local.plate,
												alias: item.local.alias,
												id: item.local.id,
												thumb: item.local.thumb,
												image: item.local.url,
											}"
											:is_specimen="item.local.type == 'specimen'"
											is_icon
										/>
									</template>

									<template v-else>
										<SpecimenPlate
											:specimen="{
												plate: item.local.cintillo,
												alias: item.local.alias,
												id: item.local.id,
											}"
											:is_specimen="item.local.type == 'specimen'"
											is_icon
										/>
									</template>
									<div
										v-if="!!item.local.status"
										class="d-flex align-items-center justify-content-center gap-x-1 mt-1"
									>
										<feather-icon
											size="18"
											v-b-tooltip.hover.top="
												item.local.in_galpon === 1 ? 'Disponible' : 'No disponible'
											"
											:class="{
												'text-danger': item.local.in_galpon === 0,
												'text-success': item.local.in_galpon === 1,
											}"
											icon="CheckCircleIcon"
										></feather-icon>
										<span>{{ item.local.status }}</span>
									</div>
									<div v-if="!!item.local.trainer">
										<small>Preparador: {{ item.local.trainer }}</small>
									</div>
									<b-badge variant="light-primary" class="mt-1" v-if="item.reason_local.id">
										Motivo: {{ item.reason_local.name }}
									</b-badge>
								</template>
								<template v-else>
									<p class="mb-0">Sin rival</p>
								</template>
							</b-td>

							<b-td class="text-center">
								<template v-if="item.rival">
									<template v-if="item.rival && item.rival.type == 'specimen'">
										<SpecimenPlate
											:specimen="{
												plate: item[item.rival.id == id_specimen ? 'local' : 'rival'].plate,
												alias: item[item.rival.id == id_specimen ? 'local' : 'rival'].alias,
												id: item[item.rival.id == id_specimen ? 'local' : 'rival'].id,
												thumb: item[item.rival.id == id_specimen ? 'local' : 'rival'].thumb,
												image: item[item.rival.id == id_specimen ? 'local' : 'rival'].url,
											}"
											:is_specimen="item.rival.type == 'specimen'"
											is_icon
										/>
									</template>

									<template v-else-if="item.rival && item.rival.type == 'chick'">
										<SpecimenPlate
											:specimen="{
												plate: item.rival.cintillo,
												alias: item.rival.alias,
												id: item.rival.id,
											}"
											:is_specimen="item.rival.type == 'specimen'"
											is_icon
										/>
									</template>

									<template v-else-if="item.rival && item.rival.type == 'other'">
										<p class="mb-0">{{ item.rival.name }}</p>
									</template>

									<div
										v-if="!!item.rival.status"
										class="d-flex align-items-center justify-content-center gap-x-1 mt-1"
									>
										<feather-icon
											size="18"
											v-b-tooltip.hover.top="
												item.rival.in_galpon === 1 ? 'Disponible' : 'No disponible'
											"
											:class="{
												'text-danger': item.rival.in_galpon === 0,
												'text-success': item.rival.in_galpon === 1,
											}"
											icon="CheckCircleIcon"
										></feather-icon>

										<span>{{ item.rival.status }}</span>
									</div>
									<div v-if="!!item.rival.trainer">
										<small>Preparador: {{ item.rival.trainer }}</small>
									</div>
									<b-badge variant="light-primary" class="mt-1" v-if="item.reason_rival.id">
										Motivo: {{ item.reason_rival.name }}
									</b-badge>
								</template>
								<template v-else>
									<p class="mb-0">Sin rival</p>
								</template>
							</b-td>

							<b-td v-if="indexTab === 1">
								<template v-if="item.result && item.result.id == 1">
									<span class="text-primary font-weight-bolder">TRIUNFO</span>
								</template>
								<template v-else-if="item.result && item.result.id == 2">
									<span class="text-warning font-weight-bolder">EMPATE</span>
								</template>
								<template v-else-if="item.result && item.result.id == 3">
									<span class="text-danger font-weight-bolder">DERROTA</span>
								</template>
							</b-td>

							<b-td v-if="id_specimen">
								<template v-for="(score, indexSc) in item.rival_specimens_score">
									<div :key="indexSc" v-if="score.id != id_specimen" class="d-flex flex-column">
										<div class="d-flex align-items-center justify-content-center">
											<span>{{ Number(score.score).toFixed(2) }}</span>
											<feather-icon
												icon="StarIcon"
												size="17"
												class="text-warning"
												style="margin-left: 5px"
											/>
										</div>
										<span>{{ score.user_name }}</span>
									</div>
								</template>
							</b-td>

							<b-td class="text-center">
								<span v-if="item.date" class="text-capitalize">{{ item.date | myGlobalDayShort }}</span>
								<span v-else>---</span>
							</b-td>

							<b-td class="text-center">
								<span class="text-capitalize">
									{{ item.created.user }}
									<br />
									{{ item.created.date | myGlobalDateTime }}
								</span>
							</b-td>

							<b-td class="text-center" v-if="isAdmin || isSupervisor || isPreparador">
								<div class="d-flex justify-content-center align-items-center gap-x-2">
									<feather-icon
										class="cursor-pointer text-danger"
										icon="DownloadIcon"
										size="20"
										v-b-tooltip.hover="`Descargar`"
										@click="downloadVideo(item)"
										v-if="isAdmin && item.video.status == '2' && item.video.url"
									/>
									<template v-if="item.video.thumb && item.category != 'torneo'">
										<feather-icon
											class="cursor-pointer"
											icon="EditIcon"
											size="20"
											v-b-tooltip.hover="`Actualizar`"
											@click="selectVideoToUpdate(item)"
										/>
										<QualifyTope
											:video="{ ...item.video, uid: item.video.url }"
											:info="{
												_curSpecimen: item.local,
												specimen: item.rival,
												rival_specimens_score: item.rival_specimens_score,
												training_rival: item.training_rival,
												confrontation_id: item.confrontation_id,
											}"
											v-if="canQualifyTope(item)"
											:training_tope_id="0"
											@refresh="init"
										/>
									</template>
									<FeatherIcon
										v-if="item.category != 'torneo' && (isAdmin || isSupervisor || isPreparador)"
										v-b-tooltip.hover="`Eliminar`"
										icon="TrashIcon"
										size="20"
										class="cursor-pointer text-danger"
										@click="removedConfrontationVideo(item)"
									/>
								</div>
							</b-td>
						</b-tr>
						<b-tr v-if="items.length == 0">
							<b-td class="text-center font-weight-bolder" :colspan="id_specimen ? 6 : 7">
								Sin videos que mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flexalign-items-centerjustify-content-center justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			id="modal-xl"
			ok-only
			ok-title="Accept"
			centered
			size="xl"
			title=""
			v-model="videoPlayerController"
			hide-footer
		>
			<b-embed type="video" aspect="21by9" controls allowfullscreen>
				<source :src="selectedVideoUrl" type="video/webm" />
			</b-embed>
		</b-modal>

		<b-modal
			v-if="updateVideo.show"
			:title="updateVideo.title"
			v-model="updateVideo.show"
			hide-footer
			no-close-on-backdrop
		>
			<UploadVideoBattle
				v-if="updateVideo.show"
				@resetTable="
					refresh()
					updateVideo.show = false
					updateVideo.info = null
				"
				:item="updateVideo.info"
				@cancel="
					updateVideo.show = false
					updateVideo.info = null
				"
			/>
		</b-modal>

		<b-sidebar v-model="sidebarFilters">
			<div class="container-sidebar">
				<h2 class="mb-2">Filtros de videos</h2>
				<div class="form-control-input">
					<p class="mb-0">Seleccionar fechas:</p>

					<flat-pickr
						v-model="rangeDate"
						class="form-control"
						:config="{ mode: 'range' }"
						placeholder="Seleccionar fechas"
						style="width: 100% !important"
					/>
				</div>

				<div class="mt-1">
					<label for="reason">Seleccionar motivo:</label>
					<v-select
						id="reason"
						v-model="reason_id"
						:options="reasons"
						label="name"
						:reduce="(option) => option.id"
						:clearable="false"
						:placeholder="'Seleccionar motivo...'"
					/>
				</div>

				<div class="container-btns">
					<button @click="keyEnter" class="btn btn-primary btn-buscar-pl mt-2">Filtrar datos</button>
					<button @click="resetFilters()" class="btn btn-outline-info btn-buscar-pl mt-2">
						Reset filtros
					</button>
				</div>
			</div>
		</b-sidebar>

		<b-modal
			v-if="modalAddVideoBySpecimen"
			hide-footer
			v-model="modalAddVideoBySpecimen"
			size="xmd"
			no-close-on-backdrop
			hide-header
			centered
		>
			<UploadSpecimenVideo
				@resetTable="refresh"
				@closing="modalAddVideoBySpecimen = false"
				:specimen_to_update_id="id_specimen"
				is_specimen_from_de
			/>
		</b-modal>

		<b-modal v-if="itemSelectToUpdate" v-model="toggleModalUpdateVideo" size="xmd" centered hide-footer hide-header>
			<UploadSpecimenVideo
				@closing="toggleModalUpdateVideo = false"
				@resetTable="resetModalUpdateVideo"
				:title="`Actualizar video del ejemplar ${
					itemSelectToUpdate.local.alias ? `${itemSelectToUpdate.local.alias} - ` : ''
				} ${itemSelectToUpdate.local.plate ? `(${itemSelectToUpdate.local.plate})` : null}`"
				:specimen_to_update_id="itemSelectToUpdate.local.id"
				reset="true"
				:data="itemSelectToUpdate"
			/>
		</b-modal>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import vSelect from "vue-select"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "@/axios"
import Ripple from "vue-ripple-directive"
import VLazyImage from "v-lazy-image/v2"
import UploadSpecimenVideo from "./UploadSpecimenVideo.vue"
import UploadVideoBattle from "./UploadVideoBattle.vue"
import participantService from "@/views/brain/tournament/views/participants/participants.service"
import FlatPickr from "vue-flatpickr-component"
import { getTournaments, getSonsBySpecimen } from "@/services/reports.service"
import TopesReasonsService from "@/views/brain/others/views/topes/services/topesReasons.service.js"

import {
	getRivalsByName,
	getVideosSpecimen,
	removedConfrontation,
	getUrlForDownloadVideo,
	getCounterVideos,
} from "./service.videolibrary"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import QualifyTope from "@/views/amg/preparacion/components/QualifyTope.vue"
import UploadFightVideo from "./UploadFightVideo.vue"
import moment from "moment"

export default {
	name: "VideoSpecimenList",
	directives: {
		Ripple,
	},
	components: {
		vSelect,
		VLazyImage,
		UploadSpecimenVideo,
		UploadVideoBattle,
		FlatPickr,
		SpecimenPlate,
		QualifyTope,
		UploadFightVideo,
	},
	props: {
		id_specimen: {
			type: Number,
			default: null,
		},
		tabCategory: {
			type: Number,
			default: null,
		},
		origin: String,
	},
	data() {
		return {
			indexTab: 0,
			loadingTable: false,
			itemSelectToUpdate: null,
			toggleModalUpdateVideo: false,
			sortBy: "created_at",
			counters: [],
			sortDesc: true,
			arrayColumns: [],
			data_sessions: [],
			modal_sessions: false,
			data: [],
			edit: [],
			list_users: true,
			create_update: false,
			disable: {
				save: false,
			},
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: 1,
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 2,
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			selectedVideoUrl: null,
			videoPlayerController: false,
			perPageOptions: [10, 25, 50, 100],
			data_specimen: {
				type: "",
				url: "",
				plaque: "",
				alias: "",
				id: "",
				size: 300000000,
				last_left: false,
				last_right: false,
			},
			modal_image: false,
			index: null,
			selectUploadVideo: null,
			modalUploadVideo: false,
			modalUploadVideoTitle: null,
			date1In: null,
			date2In: null,
			tournamentIn: null,
			resultIn: null,
			originIn: null,
			pageIn: 1,
			perPageIn: 50,
			result: null,
			resultFilter: null,
			rivalIn: null,
			typeIn: null,
			tournaments: [
				{
					id: null,
					name: "TODOS",
				},
			],
			resultsArray: [
				{
					id: null,
					name: "TODOS",
				},
				{
					id: 1,
					name: "TRIUNFO",
				},
				{
					id: 2,
					name: "EMPATE",
				},
				{
					id: 3,
					name: "DERROTA",
				},
			],
			typeArray: [
				{
					id: null,
					name: "TODOS",
				},
				{
					id: 1,
					name: "INTERNO",
				},
				{
					id: 2,
					name: "EXTERNO",
				},
			],
			categoryArray: [
				// {
				// 	id: null,
				// 	name: "TODOS",
				//   disabled: false,
				// },
				{
					id: 1,
					name: "TOPE",
					disabled: false,
				},
				{
					id: 2,
					name: "PELEA",
					disabled: true,
				},
				{
					id: 3,
					name: "TORNEO",
					disabled: false,
				},
			],
			categoryIn: null,
			participant: null,
			participantArray: [
				{
					value: null,
					label: "TODOS",
				},
			],
			rivalsArray: [
				{
					value: null,
					label: "TODOS",
				},
			],
			sidebarFilters: false,
			dataSelectVideo: {
				url: null,
				type: null,
				text: null,
				text_url: null,
				open: false,
			},
			modalUploadVideo: false,
			selectUploadVideo: null,
			arrayMonths: [
				{
					name: "Enero",
					id: 1,
				},
				{
					name: "Febrero",
					id: 2,
				},
				{
					name: "Marzo",
					id: 3,
				},
				{
					name: "Abril",
					id: 4,
				},
				{
					name: "Mayo",
					id: 5,
				},
				{
					name: "Junio",
					id: 6,
				},
				{
					name: "Julio",
					id: 7,
				},
				{
					name: "Agosto",
					id: 8,
				},
				{
					name: "Setiembre",
					id: 9,
				},
				{
					name: "Octubre",
					id: 10,
				},
				{
					name: "Noviembre",
					id: 11,
				},
				{
					name: "Diciembre",
					id: 12,
				},
			],
			arrayYears: [],
			selectMonth: null,
			selectYear: null,
			clickIcon: false,
			rangeDate: null,
			modalAddVideoBySpecimen: false,
			specimenAliasList: null,
			items: [],
			updateVideo: {
				show: false,
				info: null,
				title: "",
			},
			reason_id: null,
			reasons: [],
		}
	},
	computed: {
		...mapState("header_book", [
			"imagesVideoteca",
			"openModal",
			"uploadImages",
			"upload",
			"toggleOpenGallery",
			"indexImage",
		]),
		...mapState("video", ["videos", "uploading"]),
		tabId() {
			if (this.indexTab === 0) {
				return 1
			} else {
				return 3
			}
		},
		getCounterTope() {
			const tope = this.counters.find((it) => it.name === "tope")
			if (!tope) return 0
			return tope.counter
		},
		getCounterTournament() {
			const tournaments = this.counters.find((it) => it.name === "torneo")
			if (!tournaments) return 0
			return tournaments.counter
		},
	},
	async mounted() {
		await this.isPreloading()
		await this.getCounters()
		this.getReasons()

		this.loadingTable = true
		if (this.tabCategory == 1) {
			this.categoryArray = this.categoryArray.map((item) => {
				if (item.id == 1) {
					item.disabled = true
				} else {
					item.disabled = false
				}
				return item
			})
		} else if (this.tabCategory == 2) {
			this.categoryArray = this.categoryArray.map((item) => {
				if (item.id == 2 || item.id == 3) {
					item.disabled = true
				} else {
					item.disabled = false
				}
				return item
			})
		} else {
			this.categoryArray = this.categoryArray.map((item) => {
				return {
					...item,
					disabled: true,
				}
			})
		}
		this.setVideoteca(true)

		await this.init()
		this.isPreloading(false)
	},
	methods: {
		...mapActions("header_book", [
			"setImagesVideoteca",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setVideoteca",
		]),
		...mapActions({
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		...mapMutations("video", ["M_RESET_FORM_VIDEO", "M_SET_VIDEOS", "M_SET_VIDEO_BY_ID"]),

		async getCounters() {
			try {
				const { data } = await getCounterVideos()
				this.counters = data.data
			} catch (error) {
				throw error
			}
		},
		async init() {
			this.isPreloading()
			this.loadingTable = true
			const dateArray = this.rangeDate && this.rangeDate.split(" to ")
			const {
				message,

				videos: { data, from, last_page, to, total },
			} = await getVideosSpecimen({
				category_id: this.tabCategory ? this.tabCategory : this.tabId,
				end_date: (dateArray && dateArray[1]) || this.date2In,
				start_date: (dateArray && dateArray[0]) || this.date1In,
				fight_result_id: null,
				result_id: this.resultIn,
				rival_id: this.rivalIn || this.participant,
				tournament_id: this.tournamentIn,
				search: this.search_input,
				page: this.current_page,
				perpage: this.perpage,
				group_category: null,
				specimen_id: this.id_specimen,
				reason_id: this.reason_id,
			})

			this.items = data
			// this.M_SET_VIDEOS(data);
			this.setImagesVideoteca(
				data.map((item) => {
					return {
						...item,
						id: item.fr_id,
						id_specimens: item.local.id,
						plate: item.local.plate,
						name: item.local.alias ? item.local.alias : "",
						preload: item.video.thumb,
						type: "video",
						url: item.video.url,
					}
				})
			)
			this.start_page = from
			this.end_page = last_page
			this.total_data = total
			this.to_page = to
			// this.isPreloading(false);
			setTimeout(() => {
				this.isPreloading(false)
			}, 1000)
		},
		viewSpecimen(item, index) {
			const local =
				item.local.type == "specimen"
					? `${item.local.alias ? item.local.alias : ""} (${item.local.plate})`
					: `${item.local.cintillo}`
			const rival =
				item.rival && item.rival.plate
					? `${item.rival.alias ? item.rival.alias : ""} (${item.rival.plate})`
					: item.rival
					? item.rival.cintillo || item.rival.name
					: "Sin rival"
			this.toggleModal()
			this.setTextImage({
				title: `${local} vs ${rival}`,
				url: `/ejemplares/detalle/${item.local.id}`,
			})
			this.setIndexImage(index)
		},
		closeModalimage() {
			this.modal_image = false
		},
		resetSearch() {
			this.init()
		},
		showToast(variant, position) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title: "Video eliminado!",
						icon: "InfoIcon",
						text: "El video fue eliminado correctamente!",
						variant,
					},
				},
				{
					position,
				}
			)
		},
		deleteVideo(data) {
			this.$swal({
				title: "Estás seguro?",
				text: "No podrás revertir esto!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminalo!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				this.isPreloading()
				if (result.value) {
					axios
						.post("/api/delete-video", {
							id: data.video.id,
							iduser: this.currentUser.user_id,
							fightResult: data.fr_id,
							tournament_stage_specimen: data.tss_id,
						})
						.then(async (response) => {
							if (data.category == "TORNEO") {
								this.items = this.items.map((item) => {
									if (item.fr_id == data.fr_id) {
										return {
											...item,
											video: {
												duration: null,
												status: null,
												thumb: null,
												uid: null,
											},
										}
									} else {
										return item
									}
								})
							} else {
								// this.M_SET_VIDEOS(this.videos.filter((item) => item.id != data.id));
								this.items = this.items.filter((item) => item.fr_id != data.fr_id)

								// await this.init();
								this.to_page = this.to_page - 1
								this.total_data = this.total_data - 1
							}
							this.showToast("success", "top-right")
						})
						.catch((err) => {
							console.error(err)
						})
				}
				setTimeout(() => {
					this.isPreloading(false)
				}, 500)
			})
		},
		viewSpecimenVideo(item) {
			let index = this.videos.findIndex((x) => x.id == item.id)
			this.index = index
		},

		selectVideoToUpdate(item) {
			this.toggleModalUpdateVideo = true
			this.itemSelectToUpdate = item
		},
		async downloadVideo(item) {
			this.isPreloading()
			const fileName = `${item.category.toUpperCase()}_${item.local.plate.toUpperCase()}_${moment().format(
				"YYYY_MM_DD_HH_mm_ss"
			)}.mp4`
			try {
				const data = await getUrlForDownloadVideo(item.id)
				const downloadLink = `${data}?filename=${fileName}`
				const link = document.createElement("a")
				link.href = downloadLink
				link.download = fileName
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async resetModalUpdateVideo() {
			this.toggleModalUpdateVideo = false
			await this.refresh()
			setTimeout(() => {
				this.itemSelectToUpdate = null
			}, 300)
		},
		selectBattle(item) {
			this.updateVideo.show = true
			this.updateVideo.info = item
			this.updateVideo.title = `Actualizar video de ${
				item.local.plate || item.local.correlative || "Sin local"
			} vs ${item.rival.plate || item.rival.name || "Sin rival"}`
			// this.modalUploadVideo = true
			// this.selectUploadVideo = item
			// this.modalUploadVideoTitle = `Cargar video de ${item.entidad.plate} vs ${item.rival.alias}`
		},
		async refresh() {
			await this.init()
			this.modalAddVideoBySpecimen = false
			this.modalUploadVideo = false
			this.$emit("refresh")
		},
		async searchParticipants(search, loading) {
			clearTimeout(this.debounce)
			this.debounce = setTimeout(async () => {
				loading(true)
				if (search.length > 1) {
					const {
						data: { data },
					} = await participantService.getContactsToStatus({
						pageIn: 1,
						perPageIn: 50,
						search: search,
						status: 0,
						type_id: 1,
					})
					this.participantArray = [
						...data.map((item) => {
							return {
								label: item.participant_name,
								value: item.id,
							}
						}),
					]
				}
				loading(false)
			}, 300)
		},

		async searchRival(search, loading) {
			clearTimeout(this.debounce)
			this.debounce = setTimeout(async () => {
				loading(true)
				if (search.length > 1) {
					const { data } = await getRivalsByName({
						search: search,
					})
					this.rivalsArray = data
				}
				loading(false)
			}, 300)
		},
		//keyEnter
		async resetFilters() {
			this.date1In = null
			this.date2In = null
			this.tournamentIn = null
			this.resultIn = null
			this.originIn = null
			this.selectMonth = null
			this.selectYear = null
			this.participant = null
			this.categoryIn = null
			this.participantIn = null
			this.rivalIn = null
			this.typeIn = null
			this.reason_id = null
			await this.init()
		},
		async keyEnter() {
			await this.init()
			this.sidebarFilters = false
		},
		async getTournamentsTotal() {
			const { data } = await getTournaments()
			this.tournaments = [...this.tournaments, ...data]
		},
		canQualifyTope(info) {
			if (!info.rival_specimens_score) return true
			if (info.local.type != "specimen") return false
			let userQualifier = info.rival_specimens_score.some((score) => score.user == this.currentUser.id)
			let specimenQualified = info.rival_specimens_score.some((score) => score.id == info.local.id)
			return !(userQualifier && specimenQualified)
		},
		async removedConfrontationVideo(item) {
			const { isConfirmed } = await this.showConfirmSwal({
				title: null,
				html: `
					<p class="mb-0">¿Desea eliminar el video de ${item.local.plate || item.local.correlative} vs ${
					item.rival.plate || item.rival.correlative || item.rival.name
				}?</p>
				`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await removedConfrontation({ id: item.confrontation_id })
			// refresh specimens with days without topes
			await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()

			this.isPreloading(false)
			this.refresh()
		},
		async getReasons() {
			this.isPreloading()
			try {
				const { data } = await TopesReasonsService.getTopesReasons({
					search: "",
				})
				this.reasons = data.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		async current_page() {
			await this.init()
		},
		async perpage() {
			await this.init()
		},
		async modalAddVideoBySpecimen() {
			if (this.id_specimen) {
				const {
					data: [specimen],
				} = await getSonsBySpecimen({
					idSpecimen: parseInt(this.id_specimen),
				})
				this.specimenAliasList = specimen
			}
		},
		async sidebarFilters() {
			if (this.sidebarFilters && this.tournaments.length == 1) {
				await this.getTournamentsTotal()
			}
		},
		async indexTab() {
			await this.init()
		},
	},
	destroyed() {
		this.setVideoteca(false)
		this.setImagesVideoteca([])
		this.M_RESET_FORM_VIDEO({ category_idIn: 1, type_idIn: 1 })
		this.items = []
		this.M_SET_VIDEOS([])
	},
}
</script>

<style>
.b-avatar-badge.badge-danger {
	font-size: calc(11.4px) !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.container-span {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.event-curos-none-list-video-specimen {
	cursor: not-allowed;
}

.w-cell {
	@media screen and (min-width: 62em) {
		max-width: 160px;
	}
}

.icon-cup {
	height: 30px;
	width: 30px;
}

.badge-glow {
	max-width: 150px;
	text-overflow: ellipsis;
}

.container-sidebar {
	padding: 0 1rem 1rem 1rem;

	.form-control-input {
		margin-bottom: 0.5rem;

		p {
			font-size: 12px;
		}
	}
}

.container-btns {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
